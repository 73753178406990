import Preferences from '@/plugins/preferences'
import User from '@/store/models/user/User'
import { sha512Encode } from '@/utils/helpers'
import jwtDecode from 'jwt-decode'
import activityLog from './activity-log'
import sessions from './sessions'
import socialAuth from './social-auth'

export default {
  // login
  async login({ dispatch, rootGetters }, form) {
    const deviceInfo = rootGetters['device/all']
    const tokens = await this.$api.auth.login({
      email: form.email,
      password: await sha512Encode(form.password),
      deviceInfo,
      ...(form.mfaCode && { mfaCode: form.mfaCode }),
    })

    return dispatch('handleAuthTokens', tokens)
  },
  async logInWithDeviceId({ dispatch, getters, rootGetters }) {
    const { onboardingConsent } = getters
    const deviceInfo = rootGetters['device/all']

    const tokens = await this.$api.auth.deviceLogin({
      deviceId: deviceInfo.id,
      consent: onboardingConsent,
      deviceInfo,
    })

    return dispatch('handleAuthTokens', tokens)
  },
  async signup({ dispatch, getters, rootGetters }, form) {
    const deviceInfo = rootGetters['device/all']
    const { onboardingConsent } = getters

    const tokens = await this.$api.auth.register({
      username: form.username,
      email: form.email,
      password: await sha512Encode(form.password),
      consent: onboardingConsent,
      deviceInfo,
    })

    return dispatch('handleAuthTokens', tokens)
  },
  async forgotPassword(_, email) {
    return this.$api.auth.forgotPassword(email)
  },
  async resetPassword(_, data) {
    return this.$api.auth.resetPassword(data)
  },
  async recoverAccount(_, data) {
    return this.$api.auth.recoverAccount({
      password: await sha512Encode(data.password),
      token: data.token,
    })
  },
  async changePassword({ commit }, data) {
    const res = await this.$api.auth.changePassword({
      password: await sha512Encode(data.password),
      newPassword: await sha512Encode(data.newPassword),
      ...(data.mfaCode && { mfaCode: data.mfaCode }),
    })

    commit('setUserProp', ['hasPassword', true])

    return res
  },
  async _logout({ commit, dispatch, getters }, invalidateSession = true) {
    if (invalidateSession) {
      try {
        await this.$api.auth.invalidateSession(getters.sessionId)
      } catch (error) {
        // we don't throw log out to avoid endless loop
        console.error(error)
      }
    }

    commit('resetAuth')
    dispatch('resetApp', null, { root: true })
  },
  async logout({ dispatch }, invalidateSession = true) {
    window.dispatchEvent(new CustomEvent('rubra:auth:logout'))
    return dispatch('_logout', invalidateSession)
  },
  // helpers
  async handleOfflineAuth({ commit, getters }) {
    const { accessToken, refreshToken } = getters
    if (!accessToken || !refreshToken) throw new Error('SESSION_EXPIRED')

    // if no network, we consider user authenticated as long as we have a refresh token
    // https://ionic.io/docs/auth-connect/is-authenticated#considerations

    const { userId } = jwtDecode(accessToken)
    commit('setUserId', userId)

    return userId
  },
  async checkOrRefreshAuth({
    dispatch, commit, getters, rootGetters,
  }) {
    const { accessToken, refreshToken } = getters

    // if no network, we consider user authenticated as long as we have a refresh token
    if (!rootGetters['device/online']) return dispatch('handleOfflineAuth')

    // user only has a refreshToken for some reason
    if (!accessToken && refreshToken) {
      return dispatch('refreshAccessToken', refreshToken)
    }

    // try to recover updated tokens from extension storage if present
    if (BUILD_TARGET === 'extension') {
      const browser = await import('webextension-polyfill')
      const local = await browser.storage.local.get()
      if (local.accessToken && local.refreshToken) {
        if (local.accessToken !== accessToken || local.refreshToken !== refreshToken) {
          return dispatch('handleAuthTokens', {
            accessToken: local.accessToken,
            refreshToken: local.refreshToken,
          })
        }
      }
    }

    const { exp, userId } = jwtDecode(accessToken)
    if (exp * 1e3 <= Date.now()) {
      return dispatch('refreshAccessToken', refreshToken)
    }

    commit('setUserId', userId)
    console.log(userId)

    return userId
  },
  async refreshAccessToken({ dispatch }, refreshToken) {
    // check if refresh token is expired too
    const { exp } = jwtDecode(refreshToken)
    if (exp * 1e3 <= Date.now()) {
      throw new Error('SESSION_EXPIRED')
    }

    const tokens = await this.$api.auth.refresh({ token: refreshToken })
    console.log('Auth refreshed', tokens)

    return dispatch('handleAuthTokens', tokens)
  },
  handleAuthTokens({ commit }, { accessToken, refreshToken }) {
    const { userId, sessionId } = jwtDecode(accessToken)

    commit('setUserId', userId)
    commit('setSessionId', sessionId)

    commit('setAccessToken', accessToken)
    commit('setRefreshToken', refreshToken)

    this.$bus.$emit('auth', userId)

    return userId
  },
  //
  async getMe({ commit }) {
    const info = await this.$api.user.getMe()

    commit('setSessionId', info.sessionId)
    const { users: [user] } = await User.insertOrUpdate({ data: [info] })

    if (user.isFullAccount) {
      await Preferences.set('hasAccount', true)
    }

    return info
  },
  //
  async reset({ commit, dispatch }, form) {
    await this.$api.user.resetAccount({
      ...(form.password && { password: await sha512Encode(form.password) }),
      ...(form.mfaCode && { mfaCode: form.mfaCode }),
    })
    commit('resetAuth')
    dispatch('resetApp', null, { root: true })
  },
  async delete({ commit, dispatch }, form) {
    await this.$api.user.deleteAccount({
      password: await sha512Encode(form.password),
      ...(form.mfaCode && { mfaCode: form.mfaCode }),
    })

    await Preferences.remove('hasAccount')

    commit('resetAuth')
    dispatch('resetApp', null, { root: true })
  },
  // extension
  async handleExtensionAuth({ getters, rootGetters }, provider) {
    if (!rootGetters['device/isExtension']) return

    const deviceInfo = rootGetters['device/all']
    const consent = getters.onboardingConsent

    const payload = JSON.stringify({ deviceInfo, consent })

    const browser = await import('webextension-polyfill')
    browser.tabs.create({
      url: `${CONFIG.appUrl}/extension/auth/sso/${provider}?data=${encodeURI(payload)}`,
      active: true,
    })

    if (rootGetters['device/isExtensionPopup']) {
      window.close()
    }
  },
  async handleExtensionGuestAuth(_, opts) {
    const tokens = await this.$api.auth.deviceLogin({
      deviceId: opts.deviceInfo.id,
      consent: opts.consent,
      deviceInfo: opts.deviceInfo,
    }, opts.requestOpts)

    return tokens
  },
  ...socialAuth,
  ...sessions,
  ...activityLog,
}
