import { stateGetters, stateSetters, watchGetterAndPersist } from '@/utils/store/store-utils'
import EventBus from '@/plugins/eventbus'

const defaultState = {
  newTabOverride: true,
}

export default {
  name: 'extension',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async toggleNewTabOverride({ commit, dispatch }, enabled) {
      commit('setNewTabOverride', enabled)

      if (BUILD_TARGET === 'extension') {
        await dispatch('applyNewTabOverride', enabled)
      }
      return enabled
    },
    async applyNewTabOverride(_, enabled) {
      if (BUILD_TARGET === 'extension') {
        const browser = await import('webextension-polyfill')

        await browser.runtime.sendMessage({
          event: 'background:toggle-new-table-override',
          data: {
            enabled,
          },
        })
      }
    },
  },
}

EventBus.$once('app:created', async vm => {
  if (BUILD_TARGET === 'extension') {
    const browser = await import('webextension-polyfill')
    const result = await browser.storage.local.get('newTabOverride')
    const newTabOverride = result?.newTabOverride ?? true

    vm.$store.dispatch('extension/toggleNewTabOverride', newTabOverride)

    watchGetterAndPersist(vm.$store, 'extension/newTabOverride', 'newTabOverride')
  }
})
