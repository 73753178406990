import Vue from 'vue'
import VueRouter from 'vue-router'

import router from '@/plugins/router'
import i18n from '@/plugins/i18n'
import EventBus from '@/plugins/eventbus'
import store from '@/store'

const { isNavigationFailure, NavigationFailureType } = VueRouter

function openToast(opts) {
  EventBus.$emit('toast:open', opts)
}

const ERRORS_TO_IGNORE = ['Invalid token specified']
function getErrorString(error) {
  let message = error?.message ?? error

  if (!i18n.te(`errors.${message}`)) {
    if (!ERRORS_TO_IGNORE.includes(message)) {
      console.error(`Untranslated error message: ${message}`, error)
    }
    message = 'UNHANDLED_ERROR'
  }

  return i18n.t(`errors.${message}`)
}

function beforeHandle(error) {
  const { message, code } = error

  if (code === 'ERR_NETWORK') {
    store.commit('device/setOnline', false)
    return false
  }

  switch (message) {
    case 'Invalid token specified':
    case 'SESSION_EXPIRED':
    case 'INVALID_TOKEN':
      // user needs to log in again
      store.dispatch('auth/logout', false)

      // avoid firefox specific race condition with route guard
      setTimeout(() => {
        router.push('/auth/login').catch(err => {
          if (isNavigationFailure(err, NavigationFailureType.duplicated)) return
          throw err
        })
      }, 500)

      return false
    case 'WRONG_APP_VERSION':
      store.commit('device/setForceUpdateLayout', true)
      return false

    case 'UNDER_MAINTENANCE':
      store.commit('features/setUnderMaintenance')
      return false
    default:
      return true
  }
}

Vue.prototype.$error = {
  getI18nString(error) {
    return getErrorString(error)
  },
  toast(error, opts = {}) {
    console.error(error)
    const text = this.getI18nString(error)

    const next = beforeHandle(error)
    if (!next) return

    openToast({
      props: {
        color: 'red25 red600--text',
        timeout: 3e3,
      },
      text,
      ...opts,
    })
  },
}

export default true
